<template>
  <div class="goods-detail" @scroll="scroll">


    <template v-if="goods">
      <div class="goods-container" style="height: 100%;" v-if="goods.goods_source === 1">
        <div id="wrap" style="height: 100%; margin-bottom: 7vh;" v-show="!isShow" @touchstart="touchStart('up', $event)"
          @touchmove="touchMove('up', $event)" @touchend="touchEnd('up', $event)">
          <!--商品相册 start-->
          <div class="swipeBox" :style="{ height: 375 + 'px' }">
            <!-- <goods-gallery :data="galleryList" /> -->
            <el-carousel height="375px">
              <el-carousel-item v-for="(cover, index) in galleryList" :key="index">
                <div style="height: 100%;width: 100%;display: flex;">
                  <img :src="cover.original" alt="" style="width: 100%; object-fit: contain;">
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <!--商品相册 end-->

          <div class="goods-info-wrapper">

            <!-- <goods-spec :spuList="goods.spu_list" :selectId="goods.goods_id" @update="updateGoods"
              v-if="goods.goods_source == 1 && goods.spu_list && goods.spu_list.length > 1" /> -->

            <div class="goods-name" v-html="goods.goods_name"></div>

            <div class="price-wrapper row no-gutters align-items-end">
              <div class="revise-price col-auto">
                <div class="price-inner-wrapper row no-gutters align-items-baseline">
                  <div class="col-auto unit" v-if="mixinPriceUnit() === '￥'">{{ formData.revise_price ? '销售价￥' :
                    '会员价￥' }}</div>
                  <div class="col-auto int-price">{{ goodsReviseIntPrice }}</div>
                  <div class="col-auto float-price" v-if="goodsReviseFloatPrice">.{{ goodsReviseFloatPrice }}</div>
                  <div class="col-auto unit" v-if="mixinPriceUnit() !== '￥'">{{ mixinPriceUnit() }}</div>
                </div>
              </div>
              <div class="mkt-price col-auto" v-if="$store.getters.shopInfo.show_mktprice === 1">
                <div class="price-inner-wrapper row no-gutters align-items-baseline">
                  <div class="col-auto">市场价</div>
                  <div class="col-auto unit" v-if="mixinPriceUnit() === '￥'">￥</div>
                  <div class="col-auto int-price">{{ goodsMktIntPrice }}</div>
                  <div class="col-auto float-price" v-if="goodsMktFloatPrice">.{{ goodsMktFloatPrice }}</div>
                  <div class="col-auto unit" v-if="mixinPriceUnit() !== '￥'">{{ mixinPriceUnit() }}</div>
                </div>
              </div>
              <div class="col"></div>
            </div>

            <div v-if="goods.coupon_volist && goods.coupon_volist.length">
              <div class="couponInfo">
                <div :class="['couponInfo-left', clickLookCoupon ? 'couponInfo-height-default' : '']">
                  <div class="couponInfo-left-content" v-for="item in goods.coupon_volist" :key="item.id">{{
                    handleCouponName(item) }}</div>
                </div>
                <div class="couponInfo-right" @click="toGetCpupon">
                  <div class="couponInfo-right-content">
                    去领券
                  </div>
                </div>
              </div>
              <div class="couponInfo-bottom" @click="clickLookCoupon = !clickLookCoupon">
                <van-icon :name="clickLookCoupon ? 'arrow-down' : 'arrow-up'" />
              </div>
            </div>

          </div>

          <span class="separated"></span>
          <div class="goods-select-wrapper">
            <div class="goods-select-wrapper-item">
              <div class="left">库存</div>
              <div style="color: #f2270c;"> {{ goods.enable_quantity ? "有货" : "无货" }}</div>
            </div>
            <div class="goods-select-wrapper-item">
              <div class="left">已选</div>
              <div> 默认，{{ 1 }}件 <i class="el-icon-arrow-right"></i></div>
            </div>
            <!-- <div class="goods-select-wrapper-item">
              <div class="left">送至</div>
              <div> <i class="el-icon-arrow-right"></i></div>
            </div>
            <div class="goods-select-wrapper-item">
              <div class="left">运费</div>
              <div> {{
                postageForm.postageType === '0' || postageForm.shopPostage && postageForm.shopPostage.full_money === 0 ?
                "免运费" : `满${postageForm.shopPostage.full_money}包邮`
              }}</div>
            </div> -->
            <div class="goods-select-wrapper-item">
              <div class="left">配送方式</div>
              <div> {{ goods.ship_method === 1 ? "自营仓配送" : "厂家配送" }}</div>
            </div>
          </div>
          <span class="separated"></span>

          <div v-if="goods.show_purchasing_notice_switch === 1"
            style="background-color: #ffffff; padding: 10px 16px; border-radius: 12px; margin: 0 15px;">
            <div v-html="goods.jd_goods_purchasing_notice"></div>
          </div>
          <span class="separated" v-if="goods.show_purchasing_notice_switch === 1"></span>

          <div style="color: #999999; text-align: center; height: 35px; line-height: 35px; font-size: 14px;"
            @click="$event => (is_flag = true, touchEnd('up', $event))">
            <span v-show="is_flag">松开</span>
            <span v-show="!is_flag">上拉</span>
            <span>查看商品详情</span>
          </div>
        </div>

        <div id="wrapper1" style="height: 100%; position: relative; top: 0;" v-show="is_show"
          @touchstart="touchStart('down', $event)" @touchmove="touchMove('down', $event)"
          @touchend="touchEnd('down', $event)">
          <!-- <goods-specs-v2 ref="specs1" :goods="goods" @addToCart="handleAddToCart" @buyNow="handleBuyNow"
            @selectSku="selectSku">
          </goods-specs-v2> -->
          <div class="goods-detail-info">
            <div style="color: #999999; text-align: center; height: 35px; line-height: 35px; font-size: 14px;">
              <span v-show="!is_flag">松开</span>
              <span v-show="is_flag">下拉</span>
              <span @click="$event => (is_flag = false, touchEnd('down', $event))">返回商品信息</span>
            </div>
            <div class="goods-intro">
              <div v-if="goods.category_attrs" style="padding-bottom: 15px">
                <span>规格参数</span>
                <div class="table-box">
                  <div v-for="(item, index) in goods.category_attrs" :key="index">
                    <span class="attr-name" v-text="item.attrName"></span>
                    <span class="attr-value" v-text="item && item.vals && item.vals.join(',')"></span>
                  </div>
                </div>
              </div>
              <div v-html="goods.intro"></div>
            </div>
            <div style="height: 50px"></div>
          </div>
        </div>
      </div>

      <div class="goods-container" style="height: 100%;" v-else>
        <div class="swipeBox" :style="{ height: '375px' }">
          <!-- <goods-gallery :data="galleryList" /> -->
          <el-carousel height="375px">
            <el-carousel-item v-for="(cover, index) in galleryList" :key="index">
              <div style="height: 100%;width: 100%;display: flex;">
                <img :src="cover.original" alt="" style="width: 100%; object-fit: contain;">
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!--商品相册 end-->

        <div class="goods-info-wrapper">
          <div class="goods-name" v-html="goods.goods_name"></div>

          <div class="price-wrapper row no-gutters align-items-end">
            <div class="revise-price col-auto">
              <div class="price-inner-wrapper row no-gutters align-items-baseline">
                <div class="col-auto unit" v-if="mixinPriceUnit() === '￥'">{{ this.formData?.revise_price ? '销售价￥' :
                  '会员价￥' }}</div>
                <div class="col-auto int-price">{{ goodsReviseIntPrice }}</div>
                <div class="col-auto float-price" v-if="goodsReviseFloatPrice">.{{ goodsReviseFloatPrice }}</div>
                <div class="col-auto unit" v-if="mixinPriceUnit() !== '￥'">{{ mixinPriceUnit() }}</div>
              </div>
            </div>
            <div class="mkt-price col-auto" v-if="$store.getters.shopInfo.show_mktprice === 1">
              <div class="price-inner-wrapper row no-gutters align-items-baseline">
                <div class="col-auto">市场价</div>
                <div class="col-auto unit" v-if="mixinPriceUnit() === '￥'">￥</div>
                <div class="col-auto int-price">{{ goodsMktIntPrice }}</div>
                <div class="col-auto float-price" v-if="goodsMktFloatPrice">.{{ goodsMktFloatPrice }}</div>
                <div class="col-auto unit" v-if="mixinPriceUnit() !== '￥'">{{ mixinPriceUnit() }}</div>
              </div>
            </div>
            <div class="col"></div>
          </div>
          <!-- 多规格显示 -->
          <div style="display: flex;align-items: center;margin: 10px 0;"
            v-if="goods.is_show_spec_img && goods.spu_list && goods.spu_list.length">
            <div style="margin-right: 10px;">{{ goods.spu_list.length }}色可选</div>
            <div style="display: flex;flex: 1;overflow: auto;" class="displayScrollbar">
              <div v-for="sku in goods.spu_list" :key="sku.goods_id" style="border-radius: 5px;margin-right: 10px;"
                :style="{ 'border': sku.goods_id === goods.goods_id ? '1px solid red' : '1px solid #00000000' }"
                @click="updateGoods(sku)">
                <img :src="sku.thumbnail" alt="" style="height: 40px;width: 40px;border-radius: 5px;">
              </div>
            </div>
          </div>
          <div v-if="goods.coupon_volist && goods.coupon_volist.length">
            <div class="couponInfo">
              <div :class="['couponInfo-left', clickLookCoupon ? 'couponInfo-height-default' : '']">
                <div class="couponInfo-left-content" v-for="item in goods.coupon_volist" :key="item.id">{{
                  handleCouponName(item) }}</div>
              </div>
              <div class="couponInfo-right" @click="toGetCpupon">
                <div class="couponInfo-right-content">
                  去领券
                </div>
              </div>
            </div>
            <div class="couponInfo-bottom" @click="clickLookCoupon = !clickLookCoupon">
              <van-icon :name="clickLookCoupon ? 'arrow-down' : 'arrow-up'" />
            </div>
          </div>
        </div>

        <span class="separated"></span>

        <div class="goods-select-wrapper">
          <div class="goods-select-wrapper-item">
            <div class="left">库存</div>
            <div style="color: #f2270c;"> {{ goods.enable_quantity ? "有货" : "无货" }}</div>
          </div>
          <div class="goods-select-wrapper-item">
            <div class="left">已选</div>
            <div> 默认，{{ 1 }}件 <i class="el-icon-arrow-right"></i></div>
          </div>
          <!-- <div class="goods-select-wrapper-item">
            <div class="left">送至</div>
            <div> <i class="el-icon-arrow-right"></i></div>
          </div>
          <div class="goods-select-wrapper-item">
            <div class="left">运费</div>
            <div> {{
              postageForm.postageType === '0' || postageForm.shopPostage && postageForm.shopPostage.full_money === 0 ?
              "免运费" : `满${postageForm.shopPostage.full_money}包邮`
            }}</div>
          </div> -->
        </div>

        <span class="separated"></span>

        <div class="goods-detail-info">
          <div class="goodsTitle">商品介绍</div>
          <div class="goods-intro">
            <div v-if="goods.category_attrs" style="padding-bottom: 15px">
              <span>规格参数</span>
              <div class="table-box">
                <div v-for="(item, index) in goods.category_attrs" :key="index">
                  <span class="attr-name" v-text="item.attrName"></span>
                  <span class="attr-value" v-text="item && item.vals && item.vals.join(',')"></span>
                </div>
              </div>
            </div>
            <div v-html="goods.intro"></div>
          </div>
          <div style="height: 50px"></div>
        </div>
      </div>

      <!-- <van-goods-action ref="footer" style="z-index: 99">
        <van-goods-action-icon icon="cart" :info="cartBadge ? (cartBadge > 99 ? '99+' : cartBadge) : ''" to="/cart"
          text="购物车" />
        <van-goods-action-button color="#bbb" v-if="goods.is_auth === 0 || goods.goods_off === 0"
          :text="goods.is_auth === 0 ? '商品审核中' : '商品已下架'" :disabled="true" />
        <template v-else>
          <van-goods-action-button type="danger" text="加入购物车" :disabled="!hasStock || !distribution"
            @click="is_show ? $refs.specs1.display() : $refs.specs.display()" />
          <van-goods-action-button type="warning" text="立即购买" :disabled="!hasStock || !distribution"
            @click="is_show ? $refs.specs1.display() : $refs.specs.display()" />
        </template>
      </van-goods-action> -->
    </template>
  </div>
</template>

<script>
import * as API_Goods from "@/api/goods";

export default {
  name: "goods-detail",
  props: {
    // goods_id: {
    //   required: true,
    //   type: [String, Number],
    //   default: 0
    // },
    formData: {
      from: "",
      goods_id: '',
      good_sn: "",
      revise_price: '',//销售价
      shop_profit: "",//利润率
      islogin: true, //true 企业登录   false单独登录
      distribution_rate: "",//分销利润率
    }
  },
  data () {
    return {
      // 运费
      postageForm: {
        postageType: "0",
        shopPostage: {
          full_money: 0
        }
      },
      is_show: true,
      isShow: false,
      wrapperHeight: 0,
      wrapper1Height: 0,
      touchStartPoint: 0,
      touchEndPoint: 0,
      touchHeight: 0,
      is_flag: false, // 介绍和详情共用一个字段
      move_height: 0,
      is_iphone: true,
      galleryList: [],
      isHidden: true,
      hasStock: true,
      distribution: true,
      // 显示分销分享按钮
      show_dis: process.env.distribution,
      // 商品id
      goods_id: 0,
      // 商品信息
      goods: {},
      goodsReviseIntPrice: 0,
      goodsReviseFloatPrice: 0,
      goodsMktIntPrice: 0,
      goodsMktFloatPrice: 0,
      // 当前tab
      tabActive: 0,
      // 商品是否已被收藏
      collected: false,
      // 距离顶部距离
      offsetTop: {
        cm: 0,
        pa: 0,
      },
      // 锁住滚动出发事件
      lockScroll: false,
      // 促销信息
      promotions: "",
      // 展示分销弹框
      showDisPopup: false,
      // 显示规格弹框
      showSpecsPopup: false,
      // 返回首页
      isbackHome: false,
      swipeHeight: process.client ? document.body.clientWidth : 0,
      clickLookCoupon: false,
    };
  },
  watch: {
    goods: {
      immediate: true,
      handler (n, o) {
        if (o) {
          setTimeout(() => {
            console.log('update info');
            // this.resizeGoodsInfo()
            this.is_show = false
          }, 500)

        }
      }
    }
  },
  mounted () {
    this.getGoodsData()
    this.is_iphone = navigator.userAgent.indexOf('iPhone') > -1;
  },
  methods: {

    handleGoodsName (goods) {
      if (!goods || !goods.spu_list || !goods.spu_list.length) return
      const item = goods.spu_list.find(item => item.goods_id === goods.goods_id)
      if (!item) return
      goods.goods_name = goods.goods_name
    },
    scroll (e) {
      // if (this.goods.goods_source !== 1) return
      // const isScrollBUttom = e.target.scrollHeight - (e.target.scrollTop + e.target.getBoundingClientRect().height) <= 2
      // if (isScrollBUttom) this.is_flag = true, this.touchEnd('up')
      // if (!e.target.scrollTop) this.is_flag = false, this.touchEnd('down')
      // console.log(e, e.target.scrollTop,);
    },
    mixinPriceUnit () {
      return '￥'
    },
    async selectSku (sku) {
      this.updateGoods(sku, true)
    },
    updateGoods (select) {
      API_Goods.getGoodsDetail(select.goods_id).then(goods => {
        this.handleGoodsName(goods)
        this.goods_id = select.goods_id;
        if (goods.category_attrs) goods.category_attrs = JSON.parse(goods.category_attrs)
        goods.intro = this.handlerJdIntor(goods)
        this.goods = goods;
        const revise_price = goods.mktprice.toString().split('.');
        const mkt_price = goods.mktprice.toString().split('.');
        this.goodsReviseIntPrice = revise_price[0];
        this.goodsReviseFloatPrice = revise_price[1] || 0;
        this.goodsMktIntPrice = mkt_price[0];
        this.goodsMktFloatPrice = mkt_price[1] || 0;
        // 商品相册
        this.galleryList = goods.gallery_list || [];
        // 当前商品是否可以浏览
        this.canView = goods.is_auth !== 0 && goods.goods_off === 1;
        // 当前商品是否下架
        this.goods_off = goods.goods_off === 0;
        this.is_show = true;
      })
    },
    // ps: 可以按照滚动条的位置判断
    touchStart (type, event) {
      if (type === 'up') {
        this.wrapperHeight = document.getElementById('wrap').clientHeight // 获取当前块的可用高度
        this.touchStartPoint = parseInt(event.changedTouches[0].pageY)
        this.move_height = parseInt(event.changedTouches[0].clientY)
      } else {
        this.wrapper1Height = document.getElementById('wrapper1').clientHeight
        this.touchStartPoint = parseInt(event.changedTouches[0].pageY)
        this.move_height = parseInt(event.changedTouches[0].clientY) // 移动之前的clientY
      }
    },
    touchMove (type, event) {
      if (type === 'up') {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        //变量windowHeight是可视区的高度
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        //变量scrollHeight是滚动条的总高度
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        //滚动条到底部的条件
        if (Math.ceil(scrollTop) + windowHeight >= scrollHeight) {
          this.is_flag = true
        } else {
          this.is_flag = false
        }
      } else {
        if (event.changedTouches[0].clientY - this.move_height > 10) { // 下拉
          if (this.is_iphone) {
            this.is_flag = !(document.documentElement.scrollTop <= 0)
          } else {
            // 当is_flag为 false 上拉成功
            if (document.documentElement.scrollTop <= 0) {
              this.is_flag = !document.body.scrollTop <= 0
              if (!this.is_flag) {
                setTimeout(() => {
                  this.isShow = false
                  this.is_show = false
                  document.getElementById('wrap').setAttribute('style', 'animation: down 2s; margin-bottom: 60px; height: 100%;')
                }, 900)
              }
            }
          }
        } else {
          this.is_flag = true
        }
      }
    },
    touchEnd (type, event) {
      if (type === 'up') {
        if (this.is_flag) {
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
          this.isShow = true
          this.is_show = true
          setTimeout(() => {
            document.getElementById('wrapper1').setAttribute('style', 'animation: up 2s;') // 设置显示动画
          }, 500)
        }
      } else {
        if (!this.is_flag) {
          this.isShow = false
          this.is_show = false
          setTimeout(() => {
            document.getElementById('wrap').setAttribute('style', 'animation: down 2s; margin-bottom: 60px; height: 100%;')
          }, 1000)
        }
      }
    },
    getGoodsData () {
      API_Goods.getGoodsDetail(this.formData.goods_id, {}).then(res => {
        this.handleGoodsName(res)
        let revise_price
        if (res.category_attrs) res.category_attrs = JSON.parse(res.category_attrs)
        res.intro = this.handlerJdIntor(res)
        this.galleryList = res.gallery_list || res.goods_gallery_list || res.img_list || [];
        this.goods = res
        if (this.formData?.revise_price) revise_price = this.formData?.revise_price?.toString?.().split('.');
        else revise_price = res?.price?.toString?.().split('.') ?? res?.mktprice?.toString?.().split('.')
        const mkt_price = res.mktprice.toString().split('.');
        this.goodsReviseIntPrice = revise_price[0];
        this.goodsReviseFloatPrice = revise_price[1] || 0;
        this.goodsMktIntPrice = mkt_price[0];
        this.goodsMktFloatPrice = mkt_price[1] || 0;
        // 商品相册
        this.galleryList = res.gallery_list || [];
        // 当前商品是否可以浏览
        this.canView = res.is_auth !== 0 && res.goods_off === 1;
        // 当前商品是否下架
        this.goods_off = res.goods_off === 0;
        this.is_show = true;
        // console.log(this.goods, 99999999)
      })
    },
    handlerJdIntor (res) {
      const str = res.intro
      let regex1 = /url\((.+?)\)/g; // () 小括号
      let arr = [];
      arr = str.match(regex1);
      let newstr = "";
      let richtext = "";
      let j = 0;
      let len = 0;
      if (arr) {
        for (j = 0, len = arr.length; j < len; j++) {
          newstr = arr[j];
          newstr = newstr.replace(/url\(/, "");
          newstr = newstr.replace(/\)/, "");
          richtext += '<img src="' + newstr + '"  style="width:100%;height:auto;display:block;" />';
        }
      }
      return richtext ? richtext : str
    },
    // 京东商品样式变形修补
    resizeGoodsInfo () {
      const jdDescItems = document.getElementsByClassName("ssd-module-wrap");


      if (jdDescItems[0]) {
        // only handle 1 item
        let jdDesc = jdDescItems[0];
        let container = jdDescItems[0].parentElement;

        // 避免重复加载
        // if (container.style.height && container.style.height !== '0px') return;

        let containerWidth = container.offsetWidth;
        let containerHeight = container.offsetHeight;
        let PtableHeight = 0;

        if (document.getElementsByClassName("Ptable").length > 0) {
          PtableHeight = document.getElementsByClassName("Ptable")[0].offsetHeight + 50;
        }

        let h = document.getElementsByTagName("html")[0];
        let rootFontSize = getComputedStyle(h).fontSize.replace("px", "");
        let contentWidth = containerWidth - 0.15 * 2 * +rootFontSize; // padding 15px



        // to fix width.
        if (contentWidth < jdDesc.offsetWidth) {
          // add example :transform-origin: 0px 0px; transform: scale(0.927536); font-size: 16px; line-height: 2;
          let targetWidth = Math.min(750, contentWidth) + 30;
          let scaleValue = targetWidth / (750);
          let fontSizeValue = Math.round(14 / scaleValue);
          jdDesc.style.transformOrigin = "0px 0px";
          jdDesc.style.transform = "scale(" + scaleValue + ")";
          jdDesc.style.fontSize = fontSizeValue + "px";
          jdDesc.style.lineHeight = "2";

          container.style.height = jdDesc.offsetHeight * scaleValue + PtableHeight + "px";
        }
      } else {
        const jdDescItems = document.getElementsByClassName("content_tpl");
        if (jdDescItems && jdDescItems[0]) {
          let container = jdDescItems[0].parentElement;
          container.style.height = 'auto'
        }
        const goods_intro = document.getElementsByClassName('goods-intro')[0].clientHeight
        const client_height = document.documentElement.clientHeight
        if (goods_intro < client_height) {
          document.getElementsByClassName('goods-intro')[0].style.height = '80vh'
        }
      }
    },
    /** 是否已登录 */
    isLogin (sku_id) {
      return true;
    },
    /** 检查是否有积分兑换、团购、限时抢购活动 */
    getActivityId () {
      const { promotions } = this;
      if (!promotions || !promotions.length) return "";
      let pro;
      for (let i = 0; i < promotions.length; i++) {
        let item = promotions[i];
        if (item.exchange || item.groupbuy_goods_do || item.seckill_goods_vo) {
          pro = item;
          break;
        }
      }
      if (!pro) return "";
      return pro.activity_id;
    },
    /**处理优惠券名字 */
    handleCouponName (item) {
      const reg = /全部商品|部分商品/g;
      const content = item.coupon_content.replace(reg, "")
      if (item.coupon_type === 1) return content + "优惠券"; // 折扣
      else return content; // 满减
    },
    toGetCpupon () {
      if (!Storage.getItem("refresh_token")) {
        this.$router.push({
          path: "/login",
          query: {
            forward: `${this.$route.path}`,
          },
        });
        return
      } else {
        this.$router.push({ path: '/member/my-wallet', query: { id: 2 } })
      }
    }
  },
  updated () {
  },
};
</script>

<style lang="scss">
@keyframes up {
  from {
    top: 800px
  }

  to {
    top: 100px
  }
}

@keyframes down {
  from {
    top: -500px
  }

  to {
    top: 0
  }
}

.goods-detail {
  width: 372px;
  max-height: 70vh;
  overflow: auto;

  .van-nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99 !important;

    .van-nav-bar__left .van-nav-bar__arrow {
      color: #666;
    }

    .van-tabs__wrap::after {
      border-width: 0;
    }
  }

  .van-goods-action-button--first {
    border-radius: 40px !important;
    margin-right: 3px;
  }

  .van-goods-action-button--last {
    border-radius: 40px !important;
    margin-left: 3px;
  }

  .goods-container {
    background: #F2F2F2;

    .goods-info-wrapper {
      position: relative;
      overflow: hidden;
      background: #fff;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      padding: 12px 18px;

      .price-wrapper {
        font-size: 12px;
        padding: 0;

        .revise-price {
          font-size: 16px;
          line-height: 30px;
          color: #f2270c;
          font-family: JDZH-Regular, sans-serif;

          .int-price {
            font-size: 30px;
          }

          .float-price {
            font-size: 18px;
          }

          .unit {}
        }

        .mkt-price {
          margin-left: 8px;
          margin-bottom: 4px;
          text-decoration: line-through;
        }
      }

      .goods-name {
        font-weight: 700;
        line-height: 21px;
        font-size: 16px;
        color: #262626;
        padding: 6px 0 12px;

        &::before {
          content: "";
          height: 0;
          display: block;
          border-top: 1px solid #F5F5F5;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
        }
      }

      .couponInfo {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 12px;

        &-left {
          display: flex;
          overflow: hidden;
          max-height: 56px;
          width: 80%;
          flex-wrap: wrap;
          transition: all .3s;

          &-content {
            line-height: 18px;
            margin-right: 4px;
            margin-bottom: 4px;
            padding: 4px 8px;
            background-color: #ffebf6;
            color: #e6331d;
            border-radius: 6px;
          }
        }


        &-height-default {
          max-height: max-content;
        }

        &-right {
          display: flex;
          align-items: center;

          &-content {
            height: 26px;
            color: #fff4f5;
            line-height: 26px;
            padding: 0 10px;
            border-radius: 13px;
            background-color: #ff3926;
          }
        }


      }

      .couponInfo-bottom {
        padding-top: 6px;
        // 帮我写两个向下的箭头
        text-align: center;


      }
    }

    .goods-select-wrapper {
      border-radius: 12px;
      overflow: hidden;
      background-color: #fff;


      &-item {
        &::after {
          position: absolute;
          box-sizing: border-box;
          content: ' ';
          pointer-events: none;
          right: 16px;
          bottom: 0;
          left: 16px;
          border-bottom: 1px solid #ebedf0;
          transform: scaleY(0.5);
        }

        position: relative;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 10px 16px;
        overflow: hidden;
        color: #323233;
        font-size: 14px;
        line-height: 24px;
        background-color: #fff;
        justify-content: space-between;
      }

      .left {
        color: rgb(38, 38, 38);
        font-size: 13px;
        font-weight: 700;
      }

      .no-quantity {
        color: #f2270c;
      }

      .van-cell__title span {
        color: #262626;
        font-size: 13px;
        font-weight: 700;
      }
    }

    .separated {
      display: block;
      width: 100%;
      height: 9px;
      background-color: #F2F2F2;
    }

    .goods-detail-info {
      position: relative;
      overflow: hidden;
      background: #fff;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      .goodsTitle {
        line-height: 45px;
        font-size: 13px;
        color: #262626;
        text-align: center;
        font-weight: 700;
      }

      .goods-intro {
        width: 100%;
        overflow: hidden;
        padding: 15px;
        padding-top: 0;
        box-sizing: border-box;

        table {
          width: 100%;
        }

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }

        .Ptable {
          margin: 10px 0;
          border-collapse: collapse;
          font-size: 12px;
          border-spacing: 0;
          line-height: 18px;

          td,
          th {
            padding: 8px;
            border: 1px solid #dadada;
            text-align: left;
          }
        }
      }

      .table-box {
        margin-top: 15px;
        border-top: 1px solid #dadada;
        border-left: 1px solid #dadada;

        >div {
          display: flex;

          span {
            display: flex;
            align-items: center;
            padding: 8px;
            border-right: 1px solid #dadada;
            border-bottom: 1px solid #dadada;
          }

          .attr-name {
            width: 30%;
            justify-content: center;
          }

          .attr-value {
            width: 70%;
          }
        }
      }
    }
  }

  .van-goods-action {
    a:hover {
      color: #666;
    }

    // .van-icon-like {
    //   color: $color-main;
    // }
  }

  .buy-disabled {
    background-color: #e5e5e5;
    pointer-events: none;
  }

  .goods-auth {
    text-align: center;

    .van-button {
      margin-right: 20px;
    }

    img {
      margin-top: 50px;
      width: 100%;
    }
  }

  .btnBottom {
    height: 48px;

    .van-goods-action-icon {
      width: 110px;
      border-top: 1px solid #f3f3f3;
      box-sizing: border-box;
      padding-top: 4px;
    }

    .van-goods-action-button {
      margin: 0;
      height: 100%;
      background: #222222;
      line-height: 48px;
      border-radius: 0;
      color: #fff;
      font-size: 14px;
    }

    .van-goods-action-button.van-goods-action-button--last {
      background: #ec3a33;
    }

    .van-goods-action-button.van-goods-action-button--last.buy-disabled {
      background: #b3b3b3;
    }
  }

  .swipeBox {
    background-color: #f3f3f3;
  }
}

.displayScrollbar::-webkit-scrollbar {
  display: none;
}
</style>
