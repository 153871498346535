<template>
  <el-dialog
    title="库存编辑"
    :visible.sync="display"
    width="35%"
    class="pop-sku"
    :append-to-body="true"
  >
    <div style="text-align: center" v-if="display">
      <el-form
        :model="goodsStockData"
        ref="goodsStockDataRef"
        v-if="goodsStocknums === 1"
        style="width: 50%;"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item label="库存" prop="quantity">
          <el-input v-model.number="goodsStockData.quantity"/>
        </el-form-item>
        <el-form-item label="待发货数">
          <el-input v-model="goodsStockData.deliver_goods_quantity" disabled/>
        </el-form-item>
      </el-form>
      <en-table-layout
        :tableData="goodsStockData"
        :loading="loading"
        border
        v-if="goodsStocknums > 1"
        :span-method="arraySpanMethod"
        :stripe="false"
      >
        <template slot="table-columns">
          <el-table-column
            v-for="(item, index) in goodsStockTitle"
            v-if="item.prop !== 'sku_id'"
            :label="item.label"
            :key="index"
          >
            <template slot-scope="scope">
              <el-input
                v-if="item.prop === 'quantity'"
                @blur="checkQuantity(scope.row.quantity)"
                v-model="scope.row.quantity"
              />
              <span v-if="item.prop !== 'quantity'">{{
                  scope.row[item.prop]
                }}</span>
            </template>
          </el-table-column>
        </template>
      </en-table-layout>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="display = false" size="small">
        取 消
      </el-button>
      <el-button type="primary" @click="reserveStockGoods" size="small">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as API_goods from "@/api/goods";
import {RegExp} from "@/../ui-utils";
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  name: "goods-stock-edit",
  components: {EnTableLayout},
  data() {
    const checkQuantity = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error("库存不能为空"));
      }
      setTimeout(() => {
        if (!/^[0-9]\d*$/.test(value)) {
          callback(new Error("请输入整数"));
        } else if (!(parseInt(value) >= 0 && parseInt(value) <= 99999999)) {
          callback(new Error("请输入0 - 99999999之间的正整数"));
        } else {
          callback();
        }
      }, 500);
    };

    return {
      /** 列表loading状态 */
      loading: false,

      /** 商品库存显示*/
      display: false,

      /** 库存商品数量*/
      goodsStocknums: 0,

      /** 商品库存列表数据*/
      goodsStockData: null,

      /** 商品库存列表表头数据 */
      goodsStockTitle: [],

      /** 要合并的列的位置数组 */
      concactArray: [],

      /** 校验规则 */
      rules: {
        quantity: [{
          validator: checkQuantity,
          trigger: "blur"
        }]
      },
    };
  },
  methods: {
    /** 库存边界限制 */
    checkQuantity(value) {
      if (!value && value !== 0) {
        this.$message.error("库存不能为空");
      } else if (!RegExp.integer.test(value) && parseInt(value) !== 0) {
        this.$message.error("请输入整数");
      } else if (!(parseInt(value) >= 0 && parseInt(value) <= 99999999)) {
        this.$message.error("请输入0 - 99999999之间的正整数");
      }
    },

    /** 合并数据相同的单元格 */
    arraySpanMethod(
      {
        row,
        column,
        rowIndex,
        columnIndex
      }
    ) {
      if (columnIndex < this.goodsStockTitle.length - 3) {
        const _row = this.concactArray[rowIndex][columnIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    },

    /** 计算要合并列的位置 */
    concactArrayCom(index, item) {
      let _isMerge = false;
      /** 循环列 先循环第一列 若相同则合并 再循环第二列 依次循环 若不相同 则不合并并终止此列循环开始下一列循环 */
      let _currnetRow = [];
      for (let i = 0, _len = this.goodsStockTitle.length - 3; i < _len; i++) {
        if (this.goodsStockTitle[i].prop === "sku_id") {
          i++;
          continue;
        }
        if (
          index > 0 &&
          item[this.goodsStockTitle[i].prop] !==
          this.goodsStockData[index - 1][this.goodsStockTitle[i].prop]
        ) {
          _currnetRow[i] = 1;
          _isMerge = true;
        } else if (index > 0 && !_isMerge) {
          _currnetRow[i] = 0;
          let _count = 1;
          while (this.concactArray[index - _count][i] === 0) {
            _count++;
          }
          this.concactArray[index - _count][i] += 1;
        } else {
          // index === 0
          _currnetRow[i] = 1;
        }
      }
      this.concactArray.push(_currnetRow);
    },

    /** 库存 */
    handleStockGoods(row) {
      this.goodsId = row.goods_id;
      API_goods.getGoodsStockList(row.goods_id, {}).then(response => {
        this.goodsStockTitle = [];
        this.goodsStockData = [];
        this.goodsStocknums = response.length;

        // if (this.goodsStocknums <= 1) {
        //   this.$nextTick(() => {
        //     this.$refs.goodsStockDataRef.resetFields();
        //   });
        // }

        // 构造待发货字段
        if (this.goodsStocknums > 1) {
          this.$nextTick(() => {
            response.forEach(key => {
              // 构造待发货字段
              this.$set(
                key,
                "deliver_goods_quantity",
                parseInt(key.quantity) - parseInt(key.enable_quantity)
              );
              // 构造表头
              let _skus = key.spec_list.map(elem => {
                return {
                  label: elem.spec_name,
                  prop: elem.spec_name
                };
              });
              this.goodsStockTitle = _skus.concat([
                {
                  label: "规格id",
                  prop: "sku_id"
                },
                {
                  label: "库存",
                  prop: "quantity"
                },
                {
                  label: "待发货数",
                  prop: "deliver_goods_quantity"
                }
              ]);
              // 构造表结构
              let _skuData = key.spec_list.map(elem => {
                let _map = new Map().set(elem.spec_name, elem.spec_value);
                let obj = Object.create(null);
                for (let [k, v] of _map) {
                  obj[k] = v;
                }
                return obj;
              });
              const _key = {
                sku_id: key.sku_id,
                quantity: key.quantity,
                deliver_goods_quantity: key.deliver_goods_quantity
              };
              this.goodsStockData.push(Object.assign(_key, ..._skuData));
            });
            // 计算表格合并的位置
            this.concactArray = [];
            this.goodsStockData.forEach((key, index) => {
              this.concactArrayCom(index, key);
            });
          });
        } else if (this.goodsStocknums === 1) {
          response.forEach(key => {
            // 构造待发货字段
            this.$set(
              key,
              "deliver_goods_quantity",
              parseInt(key.quantity) - parseInt(key.enable_quantity)
            );
          });
          this.goodsStockData = response[0];
        } else {
          this.goodsStocknums = 1;
          this.goodsStockData = {
            quantity: 0,
            deliver_goods_quantity: 0,
          };
        }

        this.display = true;
      });
    },

    /** 保存库存商品 */
    reserveStockGoods() {
      let _params = [];

      if (Array.isArray(this.goodsStockData)) {
        _params = this.goodsStockData.map(elem => {
          return {
            quantity_count: elem.quantity,
            sku_id: elem.sku_id
          };
        });
      } else {
        _params.push({
          quantity_count: this.goodsStockData.quantity,
          sku_id: this.goodsStockData.sku_id
        });
      }

      const _res = _params.some(key => {
        return (
          !(
            parseInt(key.quantity_count) >= 0 &&
            parseInt(key.quantity_count) < 99999999
          ) || !/^[0-9]\d*$/.test(key.quantity_count)
        );
      });

      if (_res) {
        this.$message.error("库存须为0 - 99999999之间的整数");
        return;
      }

      API_goods.reserveStockGoods(this.goodsId, _params).then(() => {
        this.display = false;
        this.$message.success("库存商品保存成功");
        this.$emit('refresh');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-table {
  width: 100%;
  overflow-x: scroll;

  & td:not(.is-left) {
    text-align: center;
  }
}

/deep/ .pop-sku {
  .toolbar {
    display: none;
  }

  .el-dialog__body {
    .el-table {
      border: 1px solid #e5e5e5;
    }
  }

  .el-table__body-wrapper {
    max-height: 400px;
    overflow-y: scroll;
  }
}
</style>
