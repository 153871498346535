<template>
  <div id="goods-zoom" class="goods-zoom-container">
    <div class="zoom-box">
      <pic-zoom :url="current.original" :big-url="current.original" scroll style="width: 400px;height: 400px"></pic-zoom>
    </div>
    <div class="swiper-container-zoom">
      <div class="swiper-wrapper" :style="{ left: `-${leftVal}px` }">
        <a v-for="item in images" :key="item.small" class="swiper-slide" :class="[item.active && 'active']"
          @mouseenter="handleHoverTumb($event, item)" @mouseleave="handleHoverTumb($event, item)">
          <img :src="item.small">
          <i class="icon-thumb-focus"></i>
        </a>
      </div>
      <template v-if="images.length > 5">
        <div class="swiper-btn swiper-btn-prev" @click="throttle(PrevFun)">
          <i class="iconfont ea-icon-arrow-up"></i>
        </div>
        <div class="swiper-btn swiper-btn-next" @click="throttle(NextFun)">
          <i class="iconfont ea-icon-arrow-down"></i>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
/**
 * 商品相册模块
 * 缩略图啥的
 */
import PicZoom from './PicZoom'
import Swiper from 'swiper'
export default {
  name: "goods-zoom",
  components: {
    PicZoom
  },
  props: ['images', 'specImg'],
  data () {
    const _current = this.images[0]
    _current.active = true
    return {
      current: _current,
      flag: true, // 用来节流防止重复点击
      leftVal: 0, // 轮播图盒子的偏移值
      imgWidth: 80, // 在这里填写你需要的图片宽度
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initZoomSwiper()
    })
  },
  watch: {
    specImg (newVal, oldVal) {
      this.current = { original: newVal.original }
    }
  },
  methods: {
    // 这里通过额外封装的节流函数触发 PrevFun() 和 NextFun(),以达到防止重复点击的效果
    throttle (fun) {
      if (this.flag) {
        this.flag = false;
        fun(); // 此为模板中传递进来的PrevFun()或NextFun()函数
        setTimeout(() => {
          this.flag = true;
        }, 650); // 设置节流间隔时间,不得小于图片过渡时间
      }
    },
    PrevFun () {//上一张
      if (this.leftVal != 0) {
        this.leftVal -= this.imgWidth
      }
    },
    NextFun () {//下一张
      if (this.leftVal == (this.images.length * this.imgWidth) - 400) {
        // this.leftVal += this.imgWidth
      } else { // 判断显示的图片 不是 最后一张时执行
        this.leftVal += this.imgWidth
      }
    },
    /** 初始化图片相册 */
    initZoomSwiper () {
      this.zoomSwiper = new Swiper('.swiper-container-zoom', {
        direction: 'vertical',
        loop: false,
        slidesPerView: 5,
        spaceBetween: 0,
        simulateTouch: false,
        allowTouchMove: false,
        navigation: {
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev'
        }
      })
    },
    /** 鼠标悬浮在左侧缩略图上，切换放大镜图片 */
    handleHoverTumb (event, image) {
      if (event.type === 'mouseenter') {
        this.images.map(item => {
          item.active = false
          return item
        })
        image.active = true
        this.current = image
      }
    }
  },
  destroyed () {
    // 当组件销毁时，移除放大镜的canvas节点
    const mcc = document.querySelector('.mouse-cover-canvas')
    mcc && mcc.remove()
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%
}

.goods-zoom-container {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 482px;
  border: 1px solid #ededed;
}

.swiper-container-zoom {
  position: relative;
  overflow: hidden;
  width: 400px;
  height: 80px;
  border-right: 1px solid #ededed;

  .swiper-wrapper {
    flex-direction: initial !important
  }

  .swiper-slide {
    display: block;
    position: relative;
    width: 80px;
    height: 80px !important;
    cursor: pointer;

    &.active .icon-thumb-focus {
      display: block;
    }
  }

  .icon-thumb-focus {
    display: none;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 0;
    top: 0;
    background: url(../../assets/icons-goods.png) no-repeat -141px -238px;
  }

  .swiper-btn {
    position: absolute;
    width: 20px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
    text-align: center;
    cursor: pointer;
    opacity: 0;
    transition: all ease-out .2s;

    .iconfont {
      color: #fff;
      font-size: 22px;
      line-height: 20px;
    }
  }

  &:hover .swiper-btn {
    opacity: 1
  }

  .swiper-btn-prev {
    left: 0;
    top: 0
  }

  .swiper-btn-next {
    right: 0;
    top: 0
  }
}

.zoom-box {
  width: 400px;
  height: 400px;

  /deep/ img {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
